import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import * as SC from './styles';
import { useDispatch, useSelector } from 'react-redux';
import PageContent from '../../components/PageContent/PageContent';
import { usePushQuery, useRemoveQuery, useURLParams } from '../../hooks/navigation';
import { ICompanyState } from '../../redux/company/company.types';
import { ECallHandler, ICallsState } from '../../redux/call/call.types';
import { getActiveCall, getCalls, setDateFilter, setHandlerFilter } from '../../redux/call/call.redux';
import Card from '../../components/Card/Card';
import Select, { ISelectOption } from '../../components/Select/Select';
import { EDateFilter } from '../../common/types';
import CallList from '../../components/CallList/CallList';
import CallDetails from '../../components/CallDetails/CallDetails';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import useIsMobile from '../../hooks/useIsMobile';
import FlatButton from '../../components/FlatButton/FlatButton';

function CallsPage() {
  const { t } = useTranslation();
  const { callId } = useURLParams();
  const dispatch = useDispatch();
  const pushQuery = usePushQuery();
  const removeQuery = useRemoveQuery();
  const isMobile = useIsMobile(1200);
  const { calls, activeCall, dateFilter, handlerFilter } = useSelector((state: { calls: ICallsState }) => state.calls);
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const filteredCalls = calls.filter(call => (handlerFilter ? call.handler === handlerFilter : true));
  const hasCalls = filteredCalls.length > 0;
  const dateOptions: ISelectOption<EDateFilter>[] = Object.values(EDateFilter).map(item => ({
    label: t(`calls.filterCalendar-${item}`),
    value: item,
  }));
  const handlerOptions: { label: string; value: ECallHandler | undefined }[] = [
    {
      label: t('calls.filterHandler-all'),
      value: undefined,
    },
    ...Object.values(ECallHandler).map(item => ({
      label: t(`calls.filterHandler-${item}`),
      value: item,
    })),
  ];
  const showActiveCall = callId && !!activeCall;
  const showBackOnMobile = isMobile && !!callId;

  function handleBack() {
    removeQuery(['callId']);
  }

  function handleSelectChange(name: string, value: undefined | EDateFilter | ECallHandler) {
    if (name === 'callsHandler') {
      dispatch(setHandlerFilter(value as ECallHandler));
    } else {
      dispatch(setDateFilter(value as EDateFilter));
    }
    removeQuery(['callId']);
  }

  function handleOpenCall(id: number) {
    pushQuery({
      callId: id,
    });
  }

  useEffect(() => {
    if (!companyId) return;
    dispatch(getCalls(companyId));
  }, [companyId, dateFilter]);

  useEffect(() => {
    if (!companyId || !callId) return;
    dispatch(getActiveCall(callId));
  }, [companyId, callId]);

  return (
    <Layout>
      <PageContent
        title={t('calls.title')}
        padding="small"
        notScrollableContent
        actions={
          showBackOnMobile ? (
            <FlatButton text={t('calls.back')} icon="chevron-left" onClick={handleBack} />
          ) : (
            <>
              <Select
                position="right"
                name="callsHandler"
                value={handlerFilter}
                options={handlerOptions}
                onChange={handleSelectChange}
                expand
              />
              <Select position="right" name="callsCalendar" value={dateFilter} options={dateOptions} onChange={handleSelectChange} expand />
            </>
          )
        }
      >
        <Card isFullHeight noPadding>
          <SC.CallWrapper $isEmpty={!hasCalls} $noSelectedCall={!callId && hasCalls}>
            <CallList calls={filteredCalls} onOpen={handleOpenCall} activeCallId={Number(callId)} />
            {showActiveCall ? <CallDetails call={activeCall} /> : !isMobile && <EmptyContent title={t('calls.callEmptyTitle')} />}
          </SC.CallWrapper>
        </Card>
      </PageContent>
    </Layout>
  );
}

export default CallsPage;
