import { ICall } from '../../redux/call/call.types';
import { Typography } from '../Typography/Typography';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Divider from '../Divider/Divider';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import FlatButton from '../FlatButton/FlatButton';
import { useRef, useState } from 'react';

interface ICallDetails {
  call: ICall;
}

export default function CallDetails({ call }: ICallDetails) {
  const { t } = useTranslation();
  const playerRef = useRef<AudioPlayer>(null);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  const speedOptions = [1.0, 1.25, 1.5, 1.75, 2.0];

  function changePlaybackSpeed(speed: number) {
    setPlaybackSpeed(speed);
    if (playerRef.current?.audio.current) {
      playerRef.current.audio.current.playbackRate = speed;
    }
  }

  function getTranscript() {
    if (call.messages.length === 0) return 'N/A';

    return call.messages.map((item, index) => {
      const isUser = item.role === 'user';
      const key = `${call.id}-${index}`;

      return (
        <SC.MessageContainer key={key} $isUser={isUser}>
          <SC.Message $isUser={isUser}>{item.message}</SC.Message>
        </SC.MessageContainer>
      );
    });
  }

  return (
    <SC.Wrapper key={call.id}>
      <SC.AudioWrapper>
        <AudioPlayer
          ref={playerRef}
          src={call.recordingUrl}
          customControlsSection={[RHAP_UI.ADDITIONAL_CONTROLS, RHAP_UI.MAIN_CONTROLS, RHAP_UI.VOLUME_CONTROLS]}
          customAdditionalControls={[
            <SC.CustomControls key="sound-speed">
              <a key="download" href={call.recordingUrl} download>
                {<FlatButton icon="download" text="download" />}
              </a>
              <SC.CustomControlsSpeed>
                {speedOptions.map(speed => (
                  <FlatButton
                    key={speed}
                    text={`${speed}x`}
                    onClick={() => changePlaybackSpeed(speed)}
                    variant={playbackSpeed === speed ? 'brand' : 'secondary'}
                  />
                ))}
              </SC.CustomControlsSpeed>
            </SC.CustomControls>,
          ]}
          autoPlay={false}
          autoPlayAfterSrcChange={false}
        />
      </SC.AudioWrapper>

      <Divider />
      <Typography variant="h2" bold>
        {t('calls.detailsSummary')}
      </Typography>
      <Typography variant="body">{call.summary ? call.summary : 'N/A'}</Typography>
      <Divider />
      <Typography variant="h2" bold>
        {t('calls.detailsTranscript')}
      </Typography>
      <SC.Messages>{getTranscript()}</SC.Messages>
    </SC.Wrapper>
  );
}
